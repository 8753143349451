/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutCounselingWaitingLabelRequestBodyTypename = "PutCounselingWaitingLabelRequestBody" as const;

/**
 *
 * @export
 * @interface PutCounselingWaitingLabelRequestBody
 */
export interface PutCounselingWaitingLabelRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutCounselingWaitingLabelRequestBody
   */
  waitingLabel?: PutCounselingWaitingLabelRequestBodyWaitingLabelEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutCounselingWaitingLabelRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PutCounselingWaitingLabelRequestBodyWaitingLabelEnum {
  Hurry = "hurry",
  GoOut = "go_out",
  AfterTomorrow = "after_tomorrow",
  Ambiguous = "ambiguous",
  HasContact = "has_contact",
  UrgencyLow = "urgency_low",
  UrgencyMiddle = "urgency_middle",
  UrgencyHigh = "urgency_high",
}

export function PutCounselingWaitingLabelRequestBodyFromJSON(json: any): PutCounselingWaitingLabelRequestBody {
  return PutCounselingWaitingLabelRequestBodyFromJSONTyped(json, false);
}

export function PutCounselingWaitingLabelRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutCounselingWaitingLabelRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutCounselingWaitingLabelRequestBodyTypename,

    waitingLabel: !exists(json, "waiting_label") ? undefined : json["waiting_label"],
  };
}

export function PutCounselingWaitingLabelRequestBodyToJSON(object?: PutCounselingWaitingLabelRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    waiting_label: value.waitingLabel,
  };
}
