/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorBilling,
  PharmacyCounselorBillingFromJSON,
  PharmacyCounselorBillingFromJSONTyped,
  PharmacyCounselorBillingToJSON,
  PharmacyCounselorCounselingPatient,
  PharmacyCounselorCounselingPatientFromJSON,
  PharmacyCounselorCounselingPatientFromJSONTyped,
  PharmacyCounselorCounselingPatientToJSON,
  PharmacyCounselorDelivery,
  PharmacyCounselorDeliveryFromJSON,
  PharmacyCounselorDeliveryFromJSONTyped,
  PharmacyCounselorDeliveryToJSON,
  PharmacyCounselorDispensing,
  PharmacyCounselorDispensingFromJSON,
  PharmacyCounselorDispensingFromJSONTyped,
  PharmacyCounselorDispensingToJSON,
  PharmacyCounselorFollowUp,
  PharmacyCounselorFollowUpFromJSON,
  PharmacyCounselorFollowUpFromJSONTyped,
  PharmacyCounselorFollowUpToJSON,
  PharmacyCounselorInterview,
  PharmacyCounselorInterviewFromJSON,
  PharmacyCounselorInterviewFromJSONTyped,
  PharmacyCounselorInterviewToJSON,
  PharmacyCounselorPersonalHealthRecord,
  PharmacyCounselorPersonalHealthRecordFromJSON,
  PharmacyCounselorPersonalHealthRecordFromJSONTyped,
  PharmacyCounselorPersonalHealthRecordToJSON,
  PharmacyCounselorPharmacist,
  PharmacyCounselorPharmacistFromJSON,
  PharmacyCounselorPharmacistFromJSONTyped,
  PharmacyCounselorPharmacistToJSON,
  PharmacyCounselorPharmacy,
  PharmacyCounselorPharmacyFromJSON,
  PharmacyCounselorPharmacyFromJSONTyped,
  PharmacyCounselorPharmacyToJSON,
  PharmacyCounselorPrescription,
  PharmacyCounselorPrescriptionFromJSON,
  PharmacyCounselorPrescriptionFromJSONTyped,
  PharmacyCounselorPrescriptionToJSON,
  PharmacyCounselorRefill,
  PharmacyCounselorRefillFromJSON,
  PharmacyCounselorRefillFromJSONTyped,
  PharmacyCounselorRefillToJSON,
  PharmacyCounselorServiceNeeds,
  PharmacyCounselorServiceNeedsFromJSON,
  PharmacyCounselorServiceNeedsFromJSONTyped,
  PharmacyCounselorServiceNeedsToJSON,
  PharmacyCounselorTalkroom,
  PharmacyCounselorTalkroomFromJSON,
  PharmacyCounselorTalkroomFromJSONTyped,
  PharmacyCounselorTalkroomToJSON,
  PharmacyCounselorVideoChat,
  PharmacyCounselorVideoChatFromJSON,
  PharmacyCounselorVideoChatFromJSONTyped,
  PharmacyCounselorVideoChatToJSON,
} from "./";

export const PostCounselingResponseTypename = "PostCounselingResponse" as const;

/**
 *
 * @export
 * @interface PostCounselingResponse
 */
export interface PostCounselingResponse {
  /**
   *
   * @type {number}
   * @memberof PostCounselingResponse
   */
  acceptCode?: number;
  /**
   *
   * @type {boolean}
   * @memberof PostCounselingResponse
   */
  acceptReminded?: boolean;
  /**
   *
   * @type {Date}
   * @memberof PostCounselingResponse
   */
  acceptedTime?: string;
  /**
   *
   * @type {boolean}
   * @memberof PostCounselingResponse
   */
  alarmed?: boolean;
  /**
   *
   * @type {PharmacyCounselorBilling}
   * @memberof PostCounselingResponse
   */
  billing?: PharmacyCounselorBilling;
  /**
   *
   * @type {boolean}
   * @memberof PostCounselingResponse
   */
  canceledByPatient?: boolean;
  /**
   *
   * @type {Date}
   * @memberof PostCounselingResponse
   */
  canceledTime?: string;
  /**
   *
   * @type {number}
   * @memberof PostCounselingResponse
   */
  childWeight?: number;
  /**
   *
   * @type {number}
   * @memberof PostCounselingResponse
   */
  clinicId?: number;
  /**
   *
   * @type {Date}
   * @memberof PostCounselingResponse
   */
  completedTime?: string;
  /**
   * not responded to patient
   * @type {string}
   * @memberof PostCounselingResponse
   */
  counselingNote?: string;
  /**
   *
   * @type {string}
   * @memberof PostCounselingResponse
   */
  creditCardId?: string;
  /**
   *
   * @type {PharmacyCounselorDelivery}
   * @memberof PostCounselingResponse
   */
  delivery: PharmacyCounselorDelivery;
  /**
   *
   * @type {PharmacyCounselorDispensing}
   * @memberof PostCounselingResponse
   */
  dispensing?: PharmacyCounselorDispensing;
  /**
   *
   * @type {PharmacyCounselorFollowUp}
   * @memberof PostCounselingResponse
   */
  followUp?: PharmacyCounselorFollowUp;
  /**
   *
   * @type {string}
   * @memberof PostCounselingResponse
   */
  hasMedicineNotebook?: PostCounselingResponseHasMedicineNotebookEnum;
  /**
   *
   * @type {number}
   * @memberof PostCounselingResponse
   */
  id: number;
  /**
   *
   * @type {PharmacyCounselorInterview}
   * @memberof PostCounselingResponse
   */
  interview?: PharmacyCounselorInterview;
  /**
   *
   * @type {boolean}
   * @memberof PostCounselingResponse
   */
  isAcceptCodeVisible?: boolean;
  /**
   *
   * @type {string}
   * @memberof PostCounselingResponse
   */
  managementNo?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PostCounselingResponse
   */
  medicalInformationPaths?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PostCounselingResponse
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof PostCounselingResponse
   */
  messageToPatient?: string;
  /**
   *
   * @type {object}
   * @memberof PostCounselingResponse
   */
  optionalInformation?: object;
  /**
   *
   * @type {PharmacyCounselorCounselingPatient}
   * @memberof PostCounselingResponse
   */
  patient: PharmacyCounselorCounselingPatient;
  /**
   *
   * @type {PharmacyCounselorPersonalHealthRecord}
   * @memberof PostCounselingResponse
   */
  personalHealthRecord?: PharmacyCounselorPersonalHealthRecord;
  /**
   *
   * @type {PharmacyCounselorPharmacist}
   * @memberof PostCounselingResponse
   */
  pharmacist?: PharmacyCounselorPharmacist;
  /**
   *
   * @type {PharmacyCounselorPharmacy}
   * @memberof PostCounselingResponse
   */
  pharmacy?: PharmacyCounselorPharmacy;
  /**
   *
   * @type {number}
   * @memberof PostCounselingResponse
   */
  pharmacyId: number;
  /**
   *
   * @type {string}
   * @memberof PostCounselingResponse
   */
  pharmacyMessageType?: PostCounselingResponsePharmacyMessageTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof PostCounselingResponse
   */
  preferredTimes?: Array<string>;
  /**
   *
   * @type {PharmacyCounselorPrescription}
   * @memberof PostCounselingResponse
   */
  prescription?: PharmacyCounselorPrescription;
  /**
   *
   * @type {Array<string>}
   * @memberof PostCounselingResponse
   */
  prescriptionPaths?: Array<string>;
  /**
   *
   * @type {Date}
   * @memberof PostCounselingResponse
   */
  prescriptionUpdatedTime?: string;
  /**
   *
   * @type {PharmacyCounselorRefill}
   * @memberof PostCounselingResponse
   */
  refill?: PharmacyCounselorRefill;
  /**
   * counseling request token
   * @type {string}
   * @memberof PostCounselingResponse
   */
  requestToken?: string;
  /**
   *
   * @type {string}
   * @memberof PostCounselingResponse
   */
  requestType?: PostCounselingResponseRequestTypeEnum;
  /**
   *
   * @type {Date}
   * @memberof PostCounselingResponse
   */
  requestedTime?: string;
  /**
   *
   * @type {PharmacyCounselorServiceNeeds}
   * @memberof PostCounselingResponse
   */
  serviceNeeds?: PharmacyCounselorServiceNeeds;
  /**
   *
   * @type {string}
   * @memberof PostCounselingResponse
   */
  status: PostCounselingResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PostCounselingResponse
   */
  statusDetail?: string;
  /**
   *
   * @type {PharmacyCounselorTalkroom}
   * @memberof PostCounselingResponse
   */
  talkroom?: PharmacyCounselorTalkroom;
  /**
   *
   * @type {Date}
   * @memberof PostCounselingResponse
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof PostCounselingResponse
   */
  type: PostCounselingResponseTypeEnum;
  /**
   *
   * @type {PharmacyCounselorVideoChat}
   * @memberof PostCounselingResponse
   */
  videoChat?: PharmacyCounselorVideoChat;
  /**
   *
   * @type {string}
   * @memberof PostCounselingResponse
   */
  waitingLabel?: PostCounselingResponseWaitingLabelEnum;
  /**
   *
   * @type {string}
   * @memberof PostCounselingResponse
   */
  wantsGeneric?: PostCounselingResponseWantsGenericEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostCounselingResponse
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PostCounselingResponseHasMedicineNotebookEnum {
  None = "none",
  Paper = "paper",
  Digital = "digital",
  Yqb = "yqb",
}
/**
 * @export
 * @enum {string}
 */
export enum PostCounselingResponsePharmacyMessageTypeEnum {
  Preferred = "preferred",
  Busy = "busy",
  LongTimeDispensing = "long_time_dispensing",
  DoubtfulItem = "doubtful_item",
  OutOfStock = "out_of_stock",
  Other = "other",
}
/**
 * @export
 * @enum {string}
 */
export enum PostCounselingResponseRequestTypeEnum {
  Web = "web",
  Line = "line",
}
/**
 * @export
 * @enum {string}
 */
export enum PostCounselingResponseStatusEnum {
  Requested = "requested",
  Canceled = "canceled",
  Accepted = "accepted",
  Waiting = "waiting",
  CounselingStarted = "counseling_started",
  CounselingCompleted = "counseling_completed",
  BillingProcessing = "billing_processing",
  BillingCompleted = "billing_completed",
}
/**
 * @export
 * @enum {string}
 */
export enum PostCounselingResponseTypeEnum {
  OnlineCounseling = "online_counseling",
  Dispensing = "dispensing",
  FollowUpOnly = "follow_up_only",
  DeliveryOnly = "delivery_only",
  DirectVisit = "direct_visit",
}
/**
 * @export
 * @enum {string}
 */
export enum PostCounselingResponseWaitingLabelEnum {
  Hurry = "hurry",
  GoOut = "go_out",
  AfterTomorrow = "after_tomorrow",
  Ambiguous = "ambiguous",
  HasContact = "has_contact",
  UrgencyLow = "urgency_low",
  UrgencyMiddle = "urgency_middle",
  UrgencyHigh = "urgency_high",
}
/**
 * @export
 * @enum {string}
 */
export enum PostCounselingResponseWantsGenericEnum {
  None = "none",
  Want = "want",
  Consultation = "consultation",
}

export function PostCounselingResponseFromJSON(json: any): PostCounselingResponse {
  return PostCounselingResponseFromJSONTyped(json, false);
}

export function PostCounselingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCounselingResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostCounselingResponseTypename,

    acceptCode: !exists(json, "accept_code") ? undefined : json["accept_code"],
    acceptReminded: !exists(json, "accept_reminded") ? undefined : json["accept_reminded"],
    acceptedTime:
      !exists(json, "accepted_time") ||
      (typeof json["accepted_time"] === "string" && json["accepted_time"].substring(0, 4) === "0001")
        ? undefined
        : json["accepted_time"],
    alarmed: !exists(json, "alarmed") ? undefined : json["alarmed"],
    billing: !exists(json, "billing") ? undefined : PharmacyCounselorBillingFromJSON(json["billing"]),
    canceledByPatient: !exists(json, "canceled_by_patient") ? undefined : json["canceled_by_patient"],
    canceledTime:
      !exists(json, "canceled_time") ||
      (typeof json["canceled_time"] === "string" && json["canceled_time"].substring(0, 4) === "0001")
        ? undefined
        : json["canceled_time"],
    childWeight: !exists(json, "child_weight")
      ? undefined
      : json["child_weight"] == ""
      ? undefined
      : json["child_weight"],
    clinicId: !exists(json, "clinic_id") ? undefined : json["clinic_id"],
    completedTime:
      !exists(json, "completed_time") ||
      (typeof json["completed_time"] === "string" && json["completed_time"].substring(0, 4) === "0001")
        ? undefined
        : json["completed_time"],
    counselingNote: !exists(json, "counseling_note") ? undefined : json["counseling_note"],
    creditCardId: !exists(json, "credit_card_id") ? undefined : json["credit_card_id"],
    delivery: PharmacyCounselorDeliveryFromJSON(json["delivery"]),
    dispensing: !exists(json, "dispensing") ? undefined : PharmacyCounselorDispensingFromJSON(json["dispensing"]),
    followUp: !exists(json, "follow_up") ? undefined : PharmacyCounselorFollowUpFromJSON(json["follow_up"]),
    hasMedicineNotebook: !exists(json, "has_medicine_notebook") ? undefined : json["has_medicine_notebook"],
    id: json["id"],
    interview: !exists(json, "interview") ? undefined : PharmacyCounselorInterviewFromJSON(json["interview"]),
    isAcceptCodeVisible: !exists(json, "is_accept_code_visible") ? undefined : json["is_accept_code_visible"],
    managementNo: !exists(json, "management_no") ? undefined : json["management_no"],
    medicalInformationPaths: !exists(json, "medical_information_paths") ? undefined : json["medical_information_paths"],
    message: !exists(json, "message") ? undefined : json["message"],
    messageToPatient: !exists(json, "message_to_patient") ? undefined : json["message_to_patient"],
    optionalInformation: !exists(json, "optional_information") ? undefined : json["optional_information"],
    patient: PharmacyCounselorCounselingPatientFromJSON(json["patient"]),
    personalHealthRecord: !exists(json, "personal_health_record")
      ? undefined
      : PharmacyCounselorPersonalHealthRecordFromJSON(json["personal_health_record"]),
    pharmacist: !exists(json, "pharmacist") ? undefined : PharmacyCounselorPharmacistFromJSON(json["pharmacist"]),
    pharmacy: !exists(json, "pharmacy") ? undefined : PharmacyCounselorPharmacyFromJSON(json["pharmacy"]),
    pharmacyId: json["pharmacy_id"],
    pharmacyMessageType: !exists(json, "pharmacy_message_type") ? undefined : json["pharmacy_message_type"],
    preferredTimes: !exists(json, "preferred_times") ? undefined : json["preferred_times"],
    prescription: !exists(json, "prescription")
      ? undefined
      : PharmacyCounselorPrescriptionFromJSON(json["prescription"]),
    prescriptionPaths: !exists(json, "prescription_paths") ? undefined : json["prescription_paths"],
    prescriptionUpdatedTime:
      !exists(json, "prescription_updated_time") ||
      (typeof json["prescription_updated_time"] === "string" &&
        json["prescription_updated_time"].substring(0, 4) === "0001")
        ? undefined
        : json["prescription_updated_time"],
    refill: !exists(json, "refill") ? undefined : PharmacyCounselorRefillFromJSON(json["refill"]),
    requestToken: !exists(json, "request_token") ? undefined : json["request_token"],
    requestType: !exists(json, "request_type") ? undefined : json["request_type"],
    requestedTime:
      !exists(json, "requested_time") ||
      (typeof json["requested_time"] === "string" && json["requested_time"].substring(0, 4) === "0001")
        ? undefined
        : json["requested_time"],
    serviceNeeds: !exists(json, "service_needs")
      ? undefined
      : PharmacyCounselorServiceNeedsFromJSON(json["service_needs"]),
    status: json["status"],
    statusDetail: !exists(json, "status_detail") ? undefined : json["status_detail"],
    talkroom: !exists(json, "talkroom") ? undefined : PharmacyCounselorTalkroomFromJSON(json["talkroom"]),
    time:
      !exists(json, "time") || (typeof json["time"] === "string" && json["time"].substring(0, 4) === "0001")
        ? undefined
        : json["time"],
    type: json["type"],
    videoChat: !exists(json, "video_chat") ? undefined : PharmacyCounselorVideoChatFromJSON(json["video_chat"]),
    waitingLabel: !exists(json, "waiting_label") ? undefined : json["waiting_label"],
    wantsGeneric: !exists(json, "wants_generic") ? undefined : json["wants_generic"],
  };
}

export function PostCounselingResponseToJSON(object?: PostCounselingResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    accept_code: value.acceptCode,
    accept_reminded: value.acceptReminded,
    accepted_time: value.acceptedTime === undefined ? undefined : moment(value.acceptedTime).tz("Asia/Tokyo").format(),
    alarmed: value.alarmed,
    billing: PharmacyCounselorBillingToJSON(value.billing),
    canceled_by_patient: value.canceledByPatient,
    canceled_time: value.canceledTime === undefined ? undefined : moment(value.canceledTime).tz("Asia/Tokyo").format(),
    child_weight: (value.childWeight as unknown as string) == "" ? undefined : value.childWeight,
    clinic_id: value.clinicId,
    completed_time:
      value.completedTime === undefined ? undefined : moment(value.completedTime).tz("Asia/Tokyo").format(),
    counseling_note: value.counselingNote,
    credit_card_id: value.creditCardId,
    delivery: PharmacyCounselorDeliveryToJSON(value.delivery),
    dispensing: PharmacyCounselorDispensingToJSON(value.dispensing),
    follow_up: PharmacyCounselorFollowUpToJSON(value.followUp),
    has_medicine_notebook: value.hasMedicineNotebook,
    id: value.id,
    interview: PharmacyCounselorInterviewToJSON(value.interview),
    is_accept_code_visible: value.isAcceptCodeVisible,
    management_no: value.managementNo,
    medical_information_paths: value.medicalInformationPaths,
    message: value.message,
    message_to_patient: value.messageToPatient,
    optional_information: value.optionalInformation,
    patient: PharmacyCounselorCounselingPatientToJSON(value.patient),
    personal_health_record: PharmacyCounselorPersonalHealthRecordToJSON(value.personalHealthRecord),
    pharmacist: PharmacyCounselorPharmacistToJSON(value.pharmacist),
    pharmacy: PharmacyCounselorPharmacyToJSON(value.pharmacy),
    pharmacy_id: value.pharmacyId,
    pharmacy_message_type: value.pharmacyMessageType,
    preferred_times: value.preferredTimes,
    prescription: PharmacyCounselorPrescriptionToJSON(value.prescription),
    prescription_paths: value.prescriptionPaths,
    prescription_updated_time:
      value.prescriptionUpdatedTime === undefined
        ? undefined
        : moment(value.prescriptionUpdatedTime).tz("Asia/Tokyo").format(),
    refill: PharmacyCounselorRefillToJSON(value.refill),
    request_token: value.requestToken,
    request_type: value.requestType,
    requested_time:
      value.requestedTime === undefined ? undefined : moment(value.requestedTime).tz("Asia/Tokyo").format(),
    service_needs: PharmacyCounselorServiceNeedsToJSON(value.serviceNeeds),
    status: value.status,
    status_detail: value.statusDetail,
    talkroom: PharmacyCounselorTalkroomToJSON(value.talkroom),
    time: value.time === undefined ? undefined : moment(value.time).tz("Asia/Tokyo").format(),
    type: value.type,
    video_chat: PharmacyCounselorVideoChatToJSON(value.videoChat),
    waiting_label: value.waitingLabel,
    wants_generic: value.wantsGeneric,
  };
}
