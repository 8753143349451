import { logger } from "@mgdx-libs/logger";
import httpStatus from "http-status";

import { errorHandlerReport } from "../";
import {
  ApiBadRequestError,
  ApiError,
  ApiForbiddenError,
  ApiInternalServerError,
  ApiNotFoundError,
  ApiTimeoutError,
  ApiUnauthorizedError,
} from "../errors";
import { getApiErrorCodeEnumToString } from "./errorCode";

type ParseErrorResponse = (errorOrResponse: Response) => Promise<{ message?: string; detail: string }>;

const parseErrorResponse: ParseErrorResponse = async (response) => {
  try {
    const json = await response.json();
    const message = getApiErrorCodeEnumToString(json.detail_code);

    return {
      message,
      detail: JSON.stringify({
        url: response.url,
        status: response.status,
        body: json,
      }),
    };
  } catch (e) {
    logger.error(e);
    return { detail: `[${response.status}] ${response.url}` };
  }
};

type CheckErrorResponse = (errorOrResponse: unknown) => Promise<string>;

export const checkErrorResponse: CheckErrorResponse = async (errorOrResponse) => {
  let responseMessage = "しばらく経ってからお試しください。";

  try {
    if (errorOrResponse instanceof DOMException) {
      throw new ApiTimeoutError(errorOrResponse);
    } else if (errorOrResponse instanceof Response) {
      const { message, detail } = await parseErrorResponse(errorOrResponse);
      if (message) {
        responseMessage = message;
      }

      if (errorOrResponse.status === httpStatus.BAD_REQUEST) {
        throw new ApiBadRequestError(detail);
      }

      if (errorOrResponse.status === httpStatus.UNAUTHORIZED) {
        throw new ApiUnauthorizedError(detail);
      }

      if (errorOrResponse.status === httpStatus.FORBIDDEN) {
        throw new ApiForbiddenError(detail);
      }

      if (errorOrResponse.status === httpStatus.NOT_FOUND) {
        throw new ApiNotFoundError(detail);
      }

      if (errorOrResponse.status === httpStatus.INTERNAL_SERVER_ERROR) {
        throw new ApiInternalServerError(detail);
      }
      // other response
      throw new ApiError(detail);
    } else if (errorOrResponse instanceof Error) {
      await errorHandlerReport(errorOrResponse);
    }
  } catch (e) {
    errorHandlerReport(e);
  }

  return responseMessage;
};
