import {
  callGetAppVersion,
  callGetNotificationPermissionStatus,
  callGetScheduledNotificationConfig,
  callYqbGetAppNetUserId,
  isCallableGetAppVersion,
} from "./publisher";
import {
  GetAppVersionData,
  GetNetUserIdData,
  GetNotificationPermissionStatusData,
  GetScheduledNotificationConfigData,
  setYqbSendAppNetUserIdHandler,
  setYqbSendAppVersionHandler,
  setYqbSendNotificationPermissionStatusHandler,
  setYqbSendScheduledNotificationConfigHandler,
  SubscribeHandler,
} from "./subscriber";

export const getAppVersion = (handler: SubscribeHandler<GetAppVersionData>, errorHandler?: () => void) => {
  setYqbSendAppVersionHandler(handler, errorHandler);

  callGetAppVersion();
};

export const getIsInMccmApp = (handler: SubscribeHandler<boolean>) => {
  if (!isCallableGetAppVersion()) {
    handler(false);
    return;
  }
  setYqbSendAppVersionHandler(
    (_) => handler(true),
    () => handler(false)
  );

  callGetAppVersion();
};

export const getAppNetUserIdFromApp = (handler: SubscribeHandler<GetNetUserIdData>, errorHandler?: () => void) => {
  // yqbSendAppNetUserIdがアプリから呼ばれた時のhandlerを設定する
  setYqbSendAppNetUserIdHandler(handler, errorHandler);

  // トリガーとして呼び出す
  callYqbGetAppNetUserId({});
};

export const getNotificationPermissionStatus = (
  handler: SubscribeHandler<GetNotificationPermissionStatusData>,
  errorHandler?: () => void
) => {
  setYqbSendNotificationPermissionStatusHandler(handler, errorHandler);

  callGetNotificationPermissionStatus();
};

export const getAppMedicineAlarmNotificationConfig = (
  handler: SubscribeHandler<GetScheduledNotificationConfigData>,
  errorHandler?: () => void
) => {
  setYqbSendScheduledNotificationConfigHandler(handler, errorHandler);

  callGetScheduledNotificationConfig();
};
