/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyInterview,
  PharmacyInterviewFromJSON,
  PharmacyInterviewFromJSONTyped,
  PharmacyInterviewToJSON,
  PharmacyInterviewCounselingType,
  PharmacyInterviewCounselingTypeFromJSON,
  PharmacyInterviewCounselingTypeFromJSONTyped,
  PharmacyInterviewCounselingTypeToJSON,
  PharmacyOptionalParameter,
  PharmacyOptionalParameterFromJSON,
  PharmacyOptionalParameterFromJSONTyped,
  PharmacyOptionalParameterToJSON,
} from "./";

export const PharmacyChainUpdatableTypename = "PharmacyChainUpdatable" as const;

/**
 *
 * @export
 * @interface PharmacyChainUpdatable
 */
export interface PharmacyChainUpdatable {
  /**
   *
   * @type {number}
   * @memberof PharmacyChainUpdatable
   */
  cutoffDay?: number;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChainUpdatable
   */
  displayServiceNotification?: boolean;
  /**
   *
   * @type {PharmacyInterview}
   * @memberof PharmacyChainUpdatable
   */
  interviewSheet?: PharmacyInterview;
  /**
   *
   * @type {string}
   * @memberof PharmacyChainUpdatable
   */
  introduceType?: PharmacyChainUpdatableIntroduceTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChainUpdatable
   */
  isExceededFollowUpCharLimit?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChainUpdatable
   */
  isFollowUpOnly?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyChainUpdatable
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyChainUpdatable
   */
  note?: string;
  /**
   *
   * @type {Array<PharmacyOptionalParameter>}
   * @memberof PharmacyChainUpdatable
   */
  optionalParameters?: Array<PharmacyOptionalParameter>;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChainUpdatable
   */
  requiredChildWeight?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChainUpdatable
   */
  requiredDateOfBirth?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChainUpdatable
   */
  usableChainFollowUp?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChainUpdatable
   */
  usableChildWeight?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChainUpdatable
   */
  usableElapsedTime?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChainUpdatable
   */
  usableOldPricing?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChainUpdatable
   */
  usableRefill?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChainUpdatable
   */
  usableUnsupportedList?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChainUpdatable
   */
  usableWaitingLabel?: boolean;
  /**
   *
   * @type {Array<PharmacyInterviewCounselingType>}
   * @memberof PharmacyChainUpdatable
   */
  usageInterviewCounselingTypes?: Array<PharmacyInterviewCounselingType>;
  /**
   *
   * @type {string}
   * @memberof PharmacyChainUpdatable
   */
  usageInterviewSheet?: PharmacyChainUpdatableUsageInterviewSheetEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyChainUpdatable
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyChainUpdatableIntroduceTypeEnum {
  Web = "web",
  Line = "line",
  App = "app",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyChainUpdatableUsageInterviewSheetEnum {
  Default = "default",
  Custom = "custom",
  Unused = "unused",
}

export function PharmacyChainUpdatableFromJSON(json: any): PharmacyChainUpdatable {
  return PharmacyChainUpdatableFromJSONTyped(json, false);
}

export function PharmacyChainUpdatableFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyChainUpdatable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyChainUpdatableTypename,

    cutoffDay: !exists(json, "cutoff_day") ? undefined : json["cutoff_day"],
    displayServiceNotification: !exists(json, "display_service_notification")
      ? undefined
      : json["display_service_notification"],
    interviewSheet: !exists(json, "interview_sheet") ? undefined : PharmacyInterviewFromJSON(json["interview_sheet"]),
    introduceType: !exists(json, "introduce_type") ? undefined : json["introduce_type"],
    isExceededFollowUpCharLimit: !exists(json, "is_exceeded_follow_up_char_limit")
      ? undefined
      : json["is_exceeded_follow_up_char_limit"],
    isFollowUpOnly: !exists(json, "is_follow_up_only") ? undefined : json["is_follow_up_only"],
    name: !exists(json, "name") ? undefined : json["name"],
    note: !exists(json, "note") ? undefined : json["note"],
    optionalParameters: !exists(json, "optional_parameters")
      ? undefined
      : (json["optional_parameters"] as Array<any>).map(PharmacyOptionalParameterFromJSON),
    requiredChildWeight: !exists(json, "required_child_weight") ? undefined : json["required_child_weight"],
    requiredDateOfBirth: !exists(json, "required_date_of_birth") ? undefined : json["required_date_of_birth"],
    usableChainFollowUp: !exists(json, "usable_chain_follow_up") ? undefined : json["usable_chain_follow_up"],
    usableChildWeight: !exists(json, "usable_child_weight") ? undefined : json["usable_child_weight"],
    usableElapsedTime: !exists(json, "usable_elapsed_time") ? undefined : json["usable_elapsed_time"],
    usableOldPricing: !exists(json, "usable_old_pricing") ? undefined : json["usable_old_pricing"],
    usableRefill: !exists(json, "usable_refill") ? undefined : json["usable_refill"],
    usableUnsupportedList: !exists(json, "usable_unsupported_list") ? undefined : json["usable_unsupported_list"],
    usableWaitingLabel: !exists(json, "usable_waiting_label") ? undefined : json["usable_waiting_label"],
    usageInterviewCounselingTypes: !exists(json, "usage_interview_counseling_types")
      ? undefined
      : (json["usage_interview_counseling_types"] as Array<any>).map(PharmacyInterviewCounselingTypeFromJSON),
    usageInterviewSheet: !exists(json, "usage_interview_sheet") ? undefined : json["usage_interview_sheet"],
  };
}

export function PharmacyChainUpdatableToJSON(object?: PharmacyChainUpdatable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    cutoff_day: value.cutoffDay,
    display_service_notification: value.displayServiceNotification,
    interview_sheet: PharmacyInterviewToJSON(value.interviewSheet),
    introduce_type: value.introduceType,
    is_exceeded_follow_up_char_limit: value.isExceededFollowUpCharLimit,
    is_follow_up_only: value.isFollowUpOnly,
    name: value.name,
    note: value.note,
    optional_parameters:
      value.optionalParameters === undefined
        ? undefined
        : (value.optionalParameters as Array<any>).map(PharmacyOptionalParameterToJSON),
    required_child_weight: value.requiredChildWeight,
    required_date_of_birth: value.requiredDateOfBirth,
    usable_chain_follow_up: value.usableChainFollowUp,
    usable_child_weight: value.usableChildWeight,
    usable_elapsed_time: value.usableElapsedTime,
    usable_old_pricing: value.usableOldPricing,
    usable_refill: value.usableRefill,
    usable_unsupported_list: value.usableUnsupportedList,
    usable_waiting_label: value.usableWaitingLabel,
    usage_interview_counseling_types:
      value.usageInterviewCounselingTypes === undefined
        ? undefined
        : (value.usageInterviewCounselingTypes as Array<any>).map(PharmacyInterviewCounselingTypeToJSON),
    usage_interview_sheet: value.usageInterviewSheet,
  };
}
