/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorCounselingRequestBilling,
  PharmacyCounselorCounselingRequestBillingFromJSON,
  PharmacyCounselorCounselingRequestBillingFromJSONTyped,
  PharmacyCounselorCounselingRequestBillingToJSON,
  PharmacyCounselorCounselingRequestFollowUp,
  PharmacyCounselorCounselingRequestFollowUpFromJSON,
  PharmacyCounselorCounselingRequestFollowUpFromJSONTyped,
  PharmacyCounselorCounselingRequestFollowUpToJSON,
  PharmacyCounselorDelivery,
  PharmacyCounselorDeliveryFromJSON,
  PharmacyCounselorDeliveryFromJSONTyped,
  PharmacyCounselorDeliveryToJSON,
  PharmacyCounselorPersonalHealthRecordRegistrable,
  PharmacyCounselorPersonalHealthRecordRegistrableFromJSON,
  PharmacyCounselorPersonalHealthRecordRegistrableFromJSONTyped,
  PharmacyCounselorPersonalHealthRecordRegistrableToJSON,
  PharmacyCounselorPostCounselingRequestPatient,
  PharmacyCounselorPostCounselingRequestPatientFromJSON,
  PharmacyCounselorPostCounselingRequestPatientFromJSONTyped,
  PharmacyCounselorPostCounselingRequestPatientToJSON,
  PharmacyCounselorPrescriptionRegistrable,
  PharmacyCounselorPrescriptionRegistrableFromJSON,
  PharmacyCounselorPrescriptionRegistrableFromJSONTyped,
  PharmacyCounselorPrescriptionRegistrableToJSON,
  PharmacyCounselorServiceNeeds,
  PharmacyCounselorServiceNeedsFromJSON,
  PharmacyCounselorServiceNeedsFromJSONTyped,
  PharmacyCounselorServiceNeedsToJSON,
} from "./";

export const PostCounselingRequestBodyTypename = "PostCounselingRequestBody" as const;

/**
 *
 * @export
 * @interface PostCounselingRequestBody
 */
export interface PostCounselingRequestBody {
  /**
   *
   * @type {PharmacyCounselorCounselingRequestBilling}
   * @memberof PostCounselingRequestBody
   */
  billing?: PharmacyCounselorCounselingRequestBilling;
  /**
   *
   * @type {number}
   * @memberof PostCounselingRequestBody
   */
  childWeight?: number;
  /**
   *
   * @type {number}
   * @memberof PostCounselingRequestBody
   */
  clinicId?: number;
  /**
   * required if type is online_counseling or payment_method is online
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  creditCardId?: string;
  /**
   *
   * @type {PharmacyCounselorDelivery}
   * @memberof PostCounselingRequestBody
   */
  delivery?: PharmacyCounselorDelivery;
  /**
   * required if direct_visit and app_name is 'pharmacy'
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  dispensingType?: PostCounselingRequestBodyDispensingTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PostCounselingRequestBody
   */
  familyAccountId?: number;
  /**
   *
   * @type {PharmacyCounselorCounselingRequestFollowUp}
   * @memberof PostCounselingRequestBody
   */
  followUp?: PharmacyCounselorCounselingRequestFollowUp;
  /**
   * required if type online_counseling or dispensing
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  hasMedicineNotebook?: PostCounselingRequestBodyHasMedicineNotebookEnum;
  /**
   *
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  message?: string;
  /**
   * E-Mail text
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  messageToPatient?: string;
  /**
   *
   * @type {object}
   * @memberof PostCounselingRequestBody
   */
  optionalInformation?: object;
  /**
   *
   * @type {PharmacyCounselorPostCounselingRequestPatient}
   * @memberof PostCounselingRequestBody
   */
  patient?: PharmacyCounselorPostCounselingRequestPatient;
  /**
   *
   * @type {PharmacyCounselorPersonalHealthRecordRegistrable}
   * @memberof PostCounselingRequestBody
   */
  personalHealthRecord?: PharmacyCounselorPersonalHealthRecordRegistrable;
  /**
   *
   * @type {number}
   * @memberof PostCounselingRequestBody
   */
  pharmacyId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof PostCounselingRequestBody
   */
  preferredTimes?: Array<string>;
  /**
   *
   * @type {PharmacyCounselorPrescriptionRegistrable}
   * @memberof PostCounselingRequestBody
   */
  prescription?: PharmacyCounselorPrescriptionRegistrable;
  /**
   *
   * @type {Array<string>}
   * @memberof PostCounselingRequestBody
   */
  prescriptions?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  requestType?: PostCounselingRequestBodyRequestTypeEnum;
  /**
   *
   * @type {PharmacyCounselorServiceNeeds}
   * @memberof PostCounselingRequestBody
   */
  serviceNeeds?: PharmacyCounselorServiceNeeds;
  /**
   *
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  type: PostCounselingRequestBodyTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  waitingLabel?: PostCounselingRequestBodyWaitingLabelEnum;
  /**
   * required if type online_counseling or dispensing
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  wantsGeneric?: PostCounselingRequestBodyWantsGenericEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PostCounselingRequestBodyDispensingTypeEnum {
  Normal = "normal",
  Quick = "quick",
}
/**
 * @export
 * @enum {string}
 */
export enum PostCounselingRequestBodyHasMedicineNotebookEnum {
  None = "none",
  Paper = "paper",
  Digital = "digital",
  Yqb = "yqb",
}
/**
 * @export
 * @enum {string}
 */
export enum PostCounselingRequestBodyRequestTypeEnum {
  Web = "web",
  Line = "line",
}
/**
 * @export
 * @enum {string}
 */
export enum PostCounselingRequestBodyTypeEnum {
  OnlineCounseling = "online_counseling",
  Dispensing = "dispensing",
  FollowUpOnly = "follow_up_only",
  DeliveryOnly = "delivery_only",
  DirectVisit = "direct_visit",
}
/**
 * @export
 * @enum {string}
 */
export enum PostCounselingRequestBodyWaitingLabelEnum {
  Hurry = "hurry",
  GoOut = "go_out",
  AfterTomorrow = "after_tomorrow",
  Ambiguous = "ambiguous",
  HasContact = "has_contact",
  UrgencyLow = "urgency_low",
  UrgencyMiddle = "urgency_middle",
  UrgencyHigh = "urgency_high",
}
/**
 * @export
 * @enum {string}
 */
export enum PostCounselingRequestBodyWantsGenericEnum {
  None = "none",
  Want = "want",
  Consultation = "consultation",
}

export function PostCounselingRequestBodyFromJSON(json: any): PostCounselingRequestBody {
  return PostCounselingRequestBodyFromJSONTyped(json, false);
}

export function PostCounselingRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostCounselingRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostCounselingRequestBodyTypename,

    billing: !exists(json, "billing") ? undefined : PharmacyCounselorCounselingRequestBillingFromJSON(json["billing"]),
    childWeight: !exists(json, "child_weight")
      ? undefined
      : json["child_weight"] == ""
      ? undefined
      : json["child_weight"],
    clinicId: !exists(json, "clinic_id") ? undefined : json["clinic_id"],
    creditCardId: !exists(json, "credit_card_id") ? undefined : json["credit_card_id"],
    delivery: !exists(json, "delivery") ? undefined : PharmacyCounselorDeliveryFromJSON(json["delivery"]),
    dispensingType: !exists(json, "dispensing_type") ? undefined : json["dispensing_type"],
    familyAccountId: !exists(json, "family_account_id") ? undefined : json["family_account_id"],
    followUp: !exists(json, "follow_up")
      ? undefined
      : PharmacyCounselorCounselingRequestFollowUpFromJSON(json["follow_up"]),
    hasMedicineNotebook: !exists(json, "has_medicine_notebook") ? undefined : json["has_medicine_notebook"],
    message: !exists(json, "message") ? undefined : json["message"],
    messageToPatient: !exists(json, "message_to_patient") ? undefined : json["message_to_patient"],
    optionalInformation: !exists(json, "optional_information") ? undefined : json["optional_information"],
    patient: !exists(json, "patient")
      ? undefined
      : PharmacyCounselorPostCounselingRequestPatientFromJSON(json["patient"]),
    personalHealthRecord: !exists(json, "personal_health_record")
      ? undefined
      : PharmacyCounselorPersonalHealthRecordRegistrableFromJSON(json["personal_health_record"]),
    pharmacyId: json["pharmacy_id"],
    preferredTimes: !exists(json, "preferred_times") ? undefined : json["preferred_times"],
    prescription: !exists(json, "prescription")
      ? undefined
      : PharmacyCounselorPrescriptionRegistrableFromJSON(json["prescription"]),
    prescriptions: !exists(json, "prescriptions") ? undefined : json["prescriptions"],
    requestType: !exists(json, "request_type") ? undefined : json["request_type"],
    serviceNeeds: !exists(json, "service_needs")
      ? undefined
      : PharmacyCounselorServiceNeedsFromJSON(json["service_needs"]),
    type: json["type"],
    waitingLabel: !exists(json, "waiting_label") ? undefined : json["waiting_label"],
    wantsGeneric: !exists(json, "wants_generic") ? undefined : json["wants_generic"],
  };
}

export function PostCounselingRequestBodyToJSON(object?: PostCounselingRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    billing: PharmacyCounselorCounselingRequestBillingToJSON(value.billing),
    child_weight: (value.childWeight as unknown as string) == "" ? undefined : value.childWeight,
    clinic_id: value.clinicId,
    credit_card_id: value.creditCardId,
    delivery: PharmacyCounselorDeliveryToJSON(value.delivery),
    dispensing_type: value.dispensingType,
    family_account_id: value.familyAccountId,
    follow_up: PharmacyCounselorCounselingRequestFollowUpToJSON(value.followUp),
    has_medicine_notebook: value.hasMedicineNotebook,
    message: value.message,
    message_to_patient: value.messageToPatient,
    optional_information: value.optionalInformation,
    patient: PharmacyCounselorPostCounselingRequestPatientToJSON(value.patient),
    personal_health_record: PharmacyCounselorPersonalHealthRecordRegistrableToJSON(value.personalHealthRecord),
    pharmacy_id: value.pharmacyId,
    preferred_times: value.preferredTimes,
    prescription: PharmacyCounselorPrescriptionRegistrableToJSON(value.prescription),
    prescriptions: value.prescriptions,
    request_type: value.requestType,
    service_needs: PharmacyCounselorServiceNeedsToJSON(value.serviceNeeds),
    type: value.type,
    waiting_label: value.waitingLabel,
    wants_generic: value.wantsGeneric,
  };
}
