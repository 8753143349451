import { CustomError } from "ts-custom-error";

export class ApiBadRequestError extends CustomError {
  public constructor(message?: string) {
    super(message);
    Object.defineProperty(this, "name", { value: "ApiBadRequestError" });
  }
}

export class ApiUnauthorizedError extends CustomError {
  public constructor(message?: string) {
    super(message);
    Object.defineProperty(this, "name", { value: "ApiUnauthorizedError" });
  }
}

export class ApiNotFoundError extends CustomError {
  public constructor(message?: string) {
    super(message);
    Object.defineProperty(this, "name", { value: "ApiNotFoundError" });
  }
}

export class ApiForbiddenError extends CustomError {
  public constructor(message?: string) {
    super(message);
    Object.defineProperty(this, "name", { value: "ApiForbiddenError" });
  }
}

export class ApiTimeoutError extends CustomError {
  public constructor(exception: DOMException) {
    super(`${exception.name} [${exception.code}] ${exception.message}`);
    Object.defineProperty(this, "name", { value: "ApiTimeoutError" });
  }
}

export class ApiInternalServerError extends CustomError {
  public constructor(message?: string) {
    super(message);
    Object.defineProperty(this, "name", { value: "ApiInternalServerError" });
  }
}

export class ApiUnprocessableEntityError extends CustomError {
  public constructor(message?: string) {
    super(message);
    Object.defineProperty(this, "name", { value: "ApiUnprocessableEntityError" });
  }
}

export class ApiError extends CustomError {
  public constructor(message?: string) {
    super(message);
    Object.defineProperty(this, "name", { value: "ApiError" });
  }
}
