/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorExternalID,
  PharmacyCounselorExternalIDFromJSON,
  PharmacyCounselorExternalIDFromJSONTyped,
  PharmacyCounselorExternalIDToJSON,
} from "./";

export const PharmacyCounselorExaminerTypename = "PharmacyCounselorExaminer" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorExaminer
 */
export interface PharmacyCounselorExaminer {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorExaminer
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorExaminer
   */
  externalId?: string;
  /**
   *
   * @type {PharmacyCounselorExternalID}
   * @memberof PharmacyCounselorExaminer
   */
  externalIdV2?: PharmacyCounselorExternalID;
  /**
   * 受診者が本人だった場合0になる
   * @type {number}
   * @memberof PharmacyCounselorExaminer
   */
  familyAccountId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorExaminer
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorExaminer
   */
  gender?: PharmacyCounselorExaminerGenderEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorExaminer
   */
  insuranceCardPath?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorExaminer
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorExaminer
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorExaminer
   */
  lastName?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyCounselorExaminer
   */
  otherMedicalImagePaths?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorExaminer
   */
  receiptCode?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorExaminer
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorExaminerGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}

export function PharmacyCounselorExaminerFromJSON(json: any): PharmacyCounselorExaminer {
  return PharmacyCounselorExaminerFromJSONTyped(json, false);
}

export function PharmacyCounselorExaminerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorExaminer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorExaminerTypename,

    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    externalId: !exists(json, "external_id") ? undefined : json["external_id"],
    externalIdV2: !exists(json, "external_id_v2")
      ? undefined
      : PharmacyCounselorExternalIDFromJSON(json["external_id_v2"]),
    familyAccountId: !exists(json, "family_account_id") ? undefined : json["family_account_id"],
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    gender: !exists(json, "gender") ? undefined : json["gender"],
    insuranceCardPath: !exists(json, "insurance_card_path") ? undefined : json["insurance_card_path"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    otherMedicalImagePaths: !exists(json, "other_medical_image_paths") ? undefined : json["other_medical_image_paths"],
    receiptCode: !exists(json, "receipt_code") ? undefined : json["receipt_code"],
  };
}

export function PharmacyCounselorExaminerToJSON(object?: PharmacyCounselorExaminer | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
    external_id: value.externalId,
    external_id_v2: PharmacyCounselorExternalIDToJSON(value.externalIdV2),
    family_account_id: value.familyAccountId,
    first_name: value.firstName,
    gender: value.gender,
    insurance_card_path: value.insuranceCardPath,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    other_medical_image_paths: value.otherMedicalImagePaths,
    receipt_code: value.receiptCode,
  };
}

export const getGenderText = (gender: PharmacyCounselorExaminerGenderEnum) => {
  switch (gender) {
    case PharmacyCounselorExaminerGenderEnum.Male:
      return "男";
    case PharmacyCounselorExaminerGenderEnum.Female:
      return "女";
    default:
      return "";
  }
};
