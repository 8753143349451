import * as httpStatus from "http-status";

import {
  ApiBadRequestError,
  ApiError,
  ApiForbiddenError,
  ApiInternalServerError,
  ApiNotFoundError,
  ApiTimeoutError,
  ApiUnauthorizedError,
  ApiUnprocessableEntityError,
} from "../errors";
import { responseToErrorMessage } from "../utils/responseToErrorMessage";

export type ApiNetworkErrorHandler = (errorOrResponse: Error | Response | DOMException) => Promise<Error>;

export const apiErrorHandler: ApiNetworkErrorHandler = async (errorOrResponse) => {
  if (errorOrResponse instanceof DOMException) {
    return new ApiTimeoutError(errorOrResponse);
  }

  if (errorOrResponse instanceof Response) {
    const errorMessage = await responseToErrorMessage(errorOrResponse);

    if (errorOrResponse.status === httpStatus.BAD_REQUEST) {
      return new ApiBadRequestError(errorMessage);
    }

    if (errorOrResponse.status === httpStatus.UNAUTHORIZED) {
      return new ApiUnauthorizedError(errorMessage);
    }

    if (errorOrResponse.status === httpStatus.FORBIDDEN) {
      return new ApiForbiddenError(errorMessage);
    }

    if (errorOrResponse.status === httpStatus.NOT_FOUND) {
      return new ApiNotFoundError(errorMessage);
    }

    if (errorOrResponse.status === httpStatus.INTERNAL_SERVER_ERROR) {
      return new ApiInternalServerError(errorMessage);
    }

    if (errorOrResponse.status === httpStatus.UNPROCESSABLE_ENTITY) {
      return new ApiUnprocessableEntityError(errorMessage);
    }

    // other response
    return new ApiError(errorMessage);
  }

  return errorOrResponse;
};
