import { User } from "@firebase/auth";
import patientApi from "@mgdx/api/lib/patientApi";
import { PatientProviderTypeEnum, PatientUpdateStatusEnum } from "@mgdx/shared/src/models/Patient";
import { PatientStatusEnum } from "@mgdx/shared/src/models/Patient";
import { disableTalk } from "@mgdx/talk/ducks";
import { toastNotice } from "@mgdx/ui/components/Toast";
import { apiErrorHandler, errorHandlerReport, firebaseErrorHandler, isFirebaseError } from "@mgdx-libs/error-handler";
import { clearAccessToken, getFirebaseAuth, setAccessToken } from "@mgdx-libs/firebase";
import { navigate } from "@mgdx-libs/link";
import { logger } from "@mgdx-libs/logger";
import { clearPersist } from "@mgdx-libs/redux-persist-ssr";
import { useLocation } from "@reach/router";
import { abortRequests, clearRequestsCache, resetRequests } from "@redux-requests/core";
import { EnhancedStore } from "@reduxjs/toolkit";
import React, { PropsWithChildren, useCallback, useState } from "react";

import { clearCurrentUser, setCurrentUserWithDispatch } from "./ducks/currentUser";
import { getInProcessOfOIDCSignup, setInProcessOfOIDCSignup } from "./hooks/useInProcessOfOIDCSignup";
import { FirebaseAuthObserver, FirebaseAuthProvider } from "./providers/FirebaseAuthProvider";
import { PreloaderForMccm } from "./ui/PreloaderForMccm";
import { canUseAppBridge } from "./utils/app-bridge/canUseAppBridge";
import { getAppNetUserIdFromApp, getIsInMccmApp } from "./utils/app-bridge/getFromApp";
import { callShowBackButtonInApp, isCallableGetAppVersion } from "./utils/app-bridge/publisher";
import {
  clearTokenAndState,
  getPatientFromFirebaseUserAndSetToStorage,
  signoutAndClearToken,
  updatePatientToEmailVerifiedAndSetToStore,
} from "./utils/user";
import { setIsInMccmApp } from "./utils/user-agent/isInMccmApp";

const AuthWithEmailExisting: React.FC<PropsWithChildren & { store: EnhancedStore; authCheckCompleted: () => void }> = ({
  children,
  store,
  authCheckCompleted,
}) => {
  const dispatch = store.dispatch;

  const firebaseAuthObserver = useCallback(
    async (firebaseUser: User | null) => {
      logger.debug("observer's auth type: email existing");

      if (firebaseUser) {
        logger.debug("firebase.User: %o", firebaseUser);
        const result = await firebaseUser.getIdTokenResult().catch(async (tokenError) => {
          if (isFirebaseError(tokenError)) {
            try {
              firebaseErrorHandler(tokenError);
            } catch (e) {
              await errorHandlerReport(e as Error);
            }
          } else {
            await errorHandlerReport(tokenError);
          }
          return undefined;
        });

        if (result === undefined) {
          await navigate("/sign-out/", { replace: true });
          return;
        }

        if (result.claims.role !== "patient") {
          logger.debug("firebase.User: different role");
          return;
        }

        logger.debug("getIdTokenResult: %o", result);
        setAccessToken(result.token);

        logger.debug("firebase.user.emailVerified: %s", firebaseUser.emailVerified);
        const patient = await patientApi.getCurrentPatient().catch(async (errorOrResponse) => {
          if (errorOrResponse instanceof Response) {
            await getFirebaseAuth().signOut();
            dispatch(disableTalk());
            dispatch(abortRequests());
            dispatch(clearRequestsCache());
            dispatch(resetRequests());
            dispatch(clearCurrentUser());
            clearAccessToken();
            clearPersist();
            try {
              throw await apiErrorHandler(errorOrResponse);
            } catch (e) {
              await errorHandlerReport(e);
            }
          } else {
            if (errorOrResponse instanceof DOMException) {
              try {
                throw await apiErrorHandler(errorOrResponse);
              } catch (e) {
                await errorHandlerReport(e as Error);
              }
            } else if (errorOrResponse instanceof Error) {
              await errorHandlerReport(errorOrResponse);
            }
          }

          return undefined;
        });

        if (!patient) return;

        setCurrentUserWithDispatch(dispatch, patient);

        const isEmailVerifiedStatus = patient.status === PatientStatusEnum.Verified;
        logger.debug("patient.status.emailVerified: %s", isEmailVerifiedStatus);

        if (
          firebaseUser.emailVerified !== isEmailVerifiedStatus &&
          patient.loginProviderType === PatientProviderTypeEnum.Email
        ) {
          if (firebaseUser.emailVerified) {
            logger.debug("update patient.status: %s", PatientUpdateStatusEnum.Verified);
            await patientApi
              .putPatientStatus({
                putPatientStatusRequestBody: {
                  status: PatientUpdateStatusEnum.Verified,
                },
              })
              .then((patient) => {
                setCurrentUserWithDispatch(dispatch, patient);
              })
              .catch(async (errorOrResponse) => {
                if (errorOrResponse instanceof Response) {
                  try {
                    throw await apiErrorHandler(errorOrResponse);
                  } catch (e) {
                    await errorHandlerReport(e as Error);
                  }
                } else if (errorOrResponse instanceof Error) {
                  await errorHandlerReport(errorOrResponse);
                }
              });
          }
        }
      } else {
        dispatch(disableTalk());
        dispatch(clearCurrentUser());
        clearAccessToken();
        clearPersist();
      }
    },
    [dispatch]
  );

  const observer: FirebaseAuthObserver = useCallback(
    async (firebaseUser) => {
      await firebaseAuthObserver(firebaseUser);
      authCheckCompleted();
    },
    [authCheckCompleted, firebaseAuthObserver]
  );

  return <FirebaseAuthProvider observer={observer}>{children}</FirebaseAuthProvider>;
};

// memo: firebaseAuthObserverと同様の処理を行っている
const AuthWithEmail: React.FC<PropsWithChildren & { store: EnhancedStore; authCheckCompleted: () => void }> = ({
  children,
  store,
  authCheckCompleted,
}) => {
  const dispatch = store.dispatch;

  const firebaseAuthWithEmail = useCallback(
    async (firebaseUser: User | null) => {
      logger.debug("observer's auth type: email");
      if (getInProcessOfOIDCSignup()) return;

      if (!firebaseUser) {
        clearTokenAndState(dispatch);
        clearPersist();
        return;
      }

      const patient = await getPatientFromFirebaseUserAndSetToStorage(firebaseUser, dispatch).catch(async (error) => {
        logger.error(error);
        await signoutAndClearToken(dispatch);
        return undefined;
      });

      if (!patient) {
        navigate("/sign-in");
        return;
      }

      logger.debug("firebase.user.emailVerified: %s", firebaseUser.emailVerified);

      const isPatientEmailVerifield = patient.status === PatientStatusEnum.Verified;
      logger.debug("patient.status.emailVerified: %s", isPatientEmailVerifield);

      if (patient.loginProviderType !== PatientProviderTypeEnum.Email) return;

      if (firebaseUser.emailVerified && !isPatientEmailVerifield) {
        logger.debug("update patient.status: %s", PatientUpdateStatusEnum.Verified);
        await updatePatientToEmailVerifiedAndSetToStore(dispatch).catch(() => {
          return;
        });
      }
    },
    [dispatch]
  );

  const observer: FirebaseAuthObserver = useCallback(
    async (firebaseUser) => {
      await firebaseAuthWithEmail(firebaseUser);
      authCheckCompleted();
    },
    [authCheckCompleted, firebaseAuthWithEmail]
  );

  return <FirebaseAuthProvider observer={observer}>{children}</FirebaseAuthProvider>;
};

const authSkipPathList = ["/oidc/matsukiyococokara", "/debug/skip-auth", "/sign-out", "/settings/leave/complete"];

const AuthInMccmApp: React.FC<PropsWithChildren & { store: EnhancedStore; authCheckCompleted: () => void }> = ({
  children,
  store,
  authCheckCompleted,
}) => {
  const location = useLocation();
  const dispatch = store.dispatch;

  const isInSkipAuthPage = authSkipPathList.some((path) => location.pathname.startsWith(path));
  const shouldSkipAuth = isInSkipAuthPage || getInProcessOfOIDCSignup();

  const firebaseAuthWithMccmOidc = useCallback(
    async (firebaseUser: User | null) => {
      logger.debug("observer's auth type: mccm oidc");
      if (shouldSkipAuth) return;

      const redirectAndStartOIDCAuth = async () => {
        setInProcessOfOIDCSignup(true);
        await navigate("/oidc/matsukiyococokara/redirect/?redirect_url=" + encodeURIComponent(window.location.href), {
          replace: true,
        });
      };

      if (!firebaseUser) {
        clearTokenAndState(dispatch);
        clearPersist();
        await redirectAndStartOIDCAuth();

        return;
      }

      const patient = await getPatientFromFirebaseUserAndSetToStorage(firebaseUser, dispatch).catch(
        async (error: Error) => {
          logger.error(error);
          await signoutAndClearToken(dispatch);
          return undefined;
        }
      );

      if (!patient) {
        redirectAndStartOIDCAuth();
        return;
      }

      const yqbNetUserId = firebaseUser.uid.match(/oidc:matsukiyococokara-yqb:(\w+)/)?.[1];

      if (canUseAppBridge()) {
        getAppNetUserIdFromApp(async ({ netUserId }) => {
          if (netUserId !== yqbNetUserId) {
            redirectAndStartOIDCAuth();
            // TODO: デバッグ用。検証完了後削除
            if (process.env.BUILD_ENV === "dev" || process.env.BUILD_ENV === "stg") {
              toastNotice("debug: NetUserID不一致");
            }
          } else {
            callShowBackButtonInApp();
            // TODO: デバッグ用。検証完了後削除
            if (process.env.BUILD_ENV === "dev" || process.env.BUILD_ENV === "stg") {
              toastNotice("debug: ログイン済み");
            }
          }
        });
      } else {
        logger.error("unexpected: can't use app bridge in daifuku app");
        logger.debug("yqbNetUserId: %s", yqbNetUserId);
        return;
      }
    },
    [dispatch, shouldSkipAuth]
  );

  const observer: FirebaseAuthObserver = useCallback(
    async (firebaseUser) => {
      await firebaseAuthWithMccmOidc(firebaseUser);
      authCheckCompleted();
    },
    [authCheckCompleted, firebaseAuthWithMccmOidc]
  );

  return (
    <FirebaseAuthProvider observer={observer}>
      {isInSkipAuthPage || !getInProcessOfOIDCSignup() ? children : <PreloaderForMccm />}
    </FirebaseAuthProvider>
  );
};

export const AuthProvider: React.FC<PropsWithChildren & { store: EnhancedStore }> = ({ children, store }) => {
  const [isAuthCheckCompleted, setIsAuthCheckCompleted] = useState(false);
  const authCheckCompleted = useCallback(() => setIsAuthCheckCompleted(true), []);

  switch (process.env.MGDX_APP_NAME) {
    case "matsukiyococokara-yqb":
      if (isCallableGetAppVersion()) {
        getIsInMccmApp((isInMccmApp) => {
          setIsInMccmApp(isInMccmApp);
        });

        return (
          <AuthInMccmApp store={store} authCheckCompleted={authCheckCompleted}>
            {isAuthCheckCompleted ? children : <PreloaderForMccm />}
          </AuthInMccmApp>
        );
      } else {
        return (
          <AuthWithEmail store={store} authCheckCompleted={authCheckCompleted}>
            {isAuthCheckCompleted ? children : <PreloaderForMccm />}
          </AuthWithEmail>
        );
      }

    default:
      return (
        <AuthWithEmailExisting store={store} authCheckCompleted={authCheckCompleted}>
          {children}
        </AuthWithEmailExisting>
      );
  }
};
